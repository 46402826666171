import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import { MDBContainer, MDBRow, MDBCol, MDBCard } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import FadeInWhenVisible from '../components/fadeInWhenVisible'
import CustomLink from '../components/CustomLink.js'

const TechnologyPartners = ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}          
          type="root"
        />
      </div>

      {post.frontmatter.section.map((type, index) => (
        <section className={index % 2 === 0 ? 'bg-white' : 'bg-gray-light'} key={index}>
          <FadeInWhenVisible>
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                    {type.title}
                  </h2>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mt-5">
                {type.feature.map((partner, subindex) => (
                  <MDBCol md={index % 2 === 0 ? '4' : '3'} className="pb-3" key={subindex}>
                    <MDBCard className="outline card-hover">
                      <CustomLink link={partner.link}>
                        <GatsbyImage
                          image={partner.image.childImageSharp.gatsbyImageData}
                          alt={partner.alttext}
                          className="rounded"
                        />
                      </CustomLink>
                    </MDBCard>
                  </MDBCol>
                ))}
              </MDBRow>
            </MDBContainer>
          </FadeInWhenVisible>
        </section>
      ))}
    </Layout>
  )
}
export default TechnologyPartners

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}   
      canonical={location.pathname} 
    />   
  )
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { name: { eq: "technology-partners" } }) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        alttext
        ctatext
        ctaslug        
        section {
          title
          feature {
            image {
              childImageSharp {
                gatsbyImageData(quality: 90) 
              }
            }
            alttext
            link
          }
        }
      }
      html
    }
  }
`